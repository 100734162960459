import { render, staticRenderFns } from "./Focalpoint.vue?vue&type=template&id=10ff7353&scoped=true&"
import script from "./Focalpoint.vue?vue&type=script&lang=js&"
export * from "./Focalpoint.vue?vue&type=script&lang=js&"
import style0 from "./Focalpoint.vue?vue&type=style&index=0&id=10ff7353&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10ff7353",
  null
  
)

export default component.exports