<template>
  <div>
    <!-- <el-carousel :height="imgHeight + 'px'">
      <el-carousel-item v-for="item in 4" :key="item">
        <img
          ref="imgHeight"
          style="width: 100%"
          src="../assets/banner.png"
          mode="widthFix"
        />
      </el-carousel-item>
    </el-carousel> -->
    <img class="banner" style="width: 100%" :src="bander" alt="" />
    <div class="box">
      <!--用户案例 | CASES -->
      <div
        style="
          display: flex;
          margin: 0 20px;
          margin-top: 104px;
          margin-bottom: 70px;
          align-items: center;
          justify-content: center;
        "
      >
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/left.png"
          alt=""
        />
        <div
          style="
            font-size: 34px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #333333;
            margin: 0 20px;
          "
        >
          企业案例 | CASES
        </div>
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/right.png"
          alt=""
        />
      </div>
      <div v-for="(item,index) in bodylist" :key="index" style="margin-top: 120px;">
        <div style="display: flex">
          <div>
            <div
              style="
                font-size: 26px;
                font-family: PingFang SC-Heavy, PingFang SC;
                font-weight: 800;
                color: #ba1c2b;
              "
            >
             {{item.product_cate}}
            </div>
            <div
              style="
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                width: 730px;
                height: 153px;
                margin-top: 30px;
              "
            >
            {{item.product_blurb}}
            </div>
          </div>
          <div>
            <img
              style="width: 430px; margin-left: 40px; height: 215px"
              :src="item.images"
              alt=""
            />
          </div>
        </div>
        <div style="display: flex;  justify-content: space-between; 	margin-top: 56px;">
          <div style="display: flex;">
            <div style="position: relative;margin-right: 22px; " 	v-for="(i,index) in item.casess" :key="index">
              <img
                style="width: 340px; height: 240px"
                :src="i.images"
                alt=""
              />
              <div
                style="
                  width: 340px;
                  height: 44px;
                 background-color: rgb(0, 0, 0,0.5)
                  border-radius: 0px 0px 8px 8px;
                 display: flex;
					align-items: center;
					justify-content: center;
                  position: absolute;
				  bottom:0;
				color: #fff;
                "
              >
                {{i.case_name}}
              </div>
            </div>
			
          </div>
          <div @click="deatile(item.cate_id)">
            <img style="width: 96px;
				margin-right: 80px;
height: 240px;" src="../assets/more.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import url from "../request/api";
export default {
  
  data() {
    return {
      imgHeight: "",
      list: [
        { name: "教学基础 设施建设" },
        { name: "校园信息化和教学信息化" },
        { name: "教育教学咨询和课程开发服务" },
        { name: "产业学院 专业共建" },
      ],
      chooseList: ["教学基础 设施建设"],
      bodylist:[],
       imgurl: url.baseUrl,
       bander:[]
    };
  },
  mounted() {
    this.leblist()
    this.banderlist()
    this.get_banner();
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener(
      "resize",
      () => {
        this.get_banner();
      },
      false
    );
  },
  methods: {
        banderlist(){
  this.$https
          .get(`/api/index/banner?name=product_banner`, )
          .then((res) => {
            
            console.log(res);
  // res.data.forEach((element,index) => {
  //         res.data[index] = this.imgurl+element
  //       });
            this.bander = res.data;
        });
    },
     leblist() {
       this.$https
        .get(`/api/cases/index`)
        .then((res) => {
        
        
        res.data.forEach(element => {
          element.images=this.imgurl+ element.images
          element.casess.forEach(i=>{
            i.images=this.imgurl+  i.image
          })
        });
          this.bodylist=res.data
            console.log(  this.bodylist);
        });
    },
    trage(item) {
      this.chooseList = [];
      if (this.chooseList.indexOf(item) == -1) {
        this.chooseList.push(item);
      } else {
        this.chooseList.splice(this.chooseList.indexOf(item), 1);
      }
    },
    deatile(id) {
      this.$router.push({
        path: '/about_us',
        query: {leid: id}
      });
    },
    get_banner() {
      //获取到图片数据之后
      this.$nextTick(function () {
        // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
        this.imgHeight = (document.body.clientWidth * 480) / 1920;
      });
    },
  },
  destroyed() {
    //切换页面后销毁监听窗口事件
    window.removeEventListener("resize", this.get_banner, false);
  },
};
</script>
<style scoped>
.trage {
  width: 330px;
  height: 58px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #ba1c2b;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ba1c2b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 28px;
  margin-bottom: 80px;
}
.trages {
  background: #ba1c2b;
  color: #fff;
}
.box {
  width: 1300px;
  margin: 56px auto;
  margin-bottom: 121px;
}
.s11 {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.left {
  width: 740px;
}

.left .list {
  width: 740px;
  height: 133px;
  cursor: pointer;
  margin-bottom: 48px;
}

.left .list:hover .info .title {
  color: #0049c2;
}

.left .list .img {
  width: 240px;
  height: 133px;
  overflow: hidden;
}

.left .list .img img {
  width: 240px;
}

.left .list .info {
  width: 480px;
}

.left .list .info .title {
  font-size: 20px;
  color: #222222;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.left .list .info .p {
  height: 42px;
  font-size: 16px;
  color: #666666;
  margin: 16px 0 23px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.left .list .info .time {
  font-size: 14px;
  color: #999999;
}

.right {
  width: 440px;
  background: #fbfbfc;
  box-sizing: border-box;
  padding: 24px;
}

.right .titles {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 10px;
}

.right .list {
  height: 48px;
  cursor: pointer;
}

.right .list:hover .title {
  color: #0049c2;
}

.right .list .indexs {
  width: 30px;
  font-size: 20px;
  color: #999999;
  text-align: center;
}

.right .list .title {
  width: 270px;
  font-size: 16px;
  color: #333333;
  margin: 0 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.right .list .num {
  font-size: 16px;
  color: #999999;
  margin-left: 5px;
}
</style>